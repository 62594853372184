import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

function LoginForm({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post('https://simplifai-backend.azurewebsites.net/login', {
        username,
        password,
      });

      // try {
      //   const response = await axios.post('http://localhost:3001/login', {
      //     username,
      //     password,
      //   });
  
      console.log('Login Response:', response.data);
  
      if (response.data.message === 'Login successful') {
        const { location, role, isApproved } = response.data;
  
        console.log('Location:', location);
        console.log('Role:', role);
        console.log('Is Approved:', isApproved);
  
        if (isApproved === false) {
          console.log('Redirecting to Pending Request');
          navigate('/pending-request'); 
        } else {
          onLogin(location, role);
          if (role === 'user') {
            navigate(`/${location.toLowerCase()}`); 
          } else if (role === 'admin') {
            navigate('/admin'); 
          }
        }
      } else if (response.data.message === 'User not found') {
        setErrorMessage('User not found. Please register.');
      } else {
        setErrorMessage('Invalid credentials or pending approval.');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('An error occurred during login.');
    }
  };

  
    return (
    <div className="container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit">Login</button>
        {errorMessage && <p className="error">{errorMessage}</p>}
      </form>
    </div>
  );
  
}

export default LoginForm;
