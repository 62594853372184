import React from 'react';

function PendingRequestPage() {
  return (
    <div>
      <h2>Your Request is Pending</h2>
      <p>Your registration request is pending or not yet approved. Please wait for approval.</p>
    </div>
  );
}

export default PendingRequestPage;
