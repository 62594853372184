import React, { useState } from 'react';
import Sidebar from "./kirklees/sidebar";
import Navbar from "./kirklees/navbar";
import Table from "./kirklees/table";
import { useNavigate } from 'react-router-dom';
import "./middlesbrough.scss";

function UserPageKirklees({ onLogout }) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('t'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
        onLogout(); 
      };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <br></br>
        <div className="banner">
        <div className="dummyBannerContent">
          Welcome Kirklees
        </div>
        <div className="BannerContent">
          Try your admin panel
        </div>
        <button className="bannerButton" onClick={() => navigate('/admin')}>
           Explore More
          </button>
      </div>

        <div className="listContainer">
          <h3>Please select from the below options</h3>
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'table' ? 'active' : ''}`}
              onClick={() => handleTabClick('table')}
            >
              Configuration file
              <img src={`${new URL('../icons/1.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
            </div>

            <a
    className={`tab ${activeTab === 'Tab1' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/0649a778-c78d-4b81-83d8-d1a0b6cd2844/page/TlocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab1')}
  >
    Signal timings
    <br></br>
    <img src={`${new URL('../icons/2.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab2' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/623c88fe-2cf5-4289-91af-c025655fbcbc/page/jHocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab2')}
  >
    Congestion and queues
    <br></br>
    <img src={`${new URL('../icons/3.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab3' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/fc257829-a7c8-4249-942c-8cc7e7de821e/page/8cocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab3')}
  >
   
    Flows and saturation
    <br></br>
    <img src={`${new URL('../icons/4.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab4' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/a509772a-b6d4-4177-b4be-27cd3ab9197a/page/M0ncC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab4')}
  >
   
   Delays and stops
   <br></br>
   <img src={`${new URL('../icons/5.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab5' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/32e22c3a-1d48-4dff-bc4c-59a53d637673/page/zl8DD"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab5')}
  >
   
   Bus data
   <br></br>
   <img src={`${new URL('../icons/6.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab5' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/u/0/reporting/2e0ccb88-dc9b-421f-8a20-f3ce57546d17/page/dxocCD"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab5')}
  >
   
   15 Minute Flows
   <br></br>
   <img src={`${new URL('../icons/6.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab6' ? 'active' : ''}`}
    href="https://console.cloud.google.com/compute/instancesDetail/zones/europe-west2-c/instances/sipp-kirklees?authuser=1&orgonly=true&project=kirklees-14c1&supportedpurview=organizationId,folder,project"
    target="_blank"
    rel="noopener noreferrer"
    onClick={() => handleTabClick('Tab6')}
  >
   
   Solutions and Simulation
   

  </a>
          </div>
          {activeTab === 'table' && (
            <React.Fragment>
              <div className="listTitle">Input and Configuration</div>
              <Table />
            </React.Fragment>
          )}
          {activeTab === 'Tab1' && (
            <React.Fragment>
              <div className="listTitle">Looker report</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab2' && (
            <React.Fragment>
              <div className="listTitle">Looker report 2</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab3' && (
            <React.Fragment>
              <div className="listTitle">Looker report 3</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab4' && (
            <React.Fragment>
              <div className="listTitle">Looker report 4</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab5' && (
              <React.Fragment>
                <div className="listTitle">Sipp AI Planner</div>
              </React.Fragment>
            )}

          <button className="logout" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default UserPageKirklees;
