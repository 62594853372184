import React, { useState } from 'react';
import Sidebar from "./middlesbrough/sidebar";
import Navbar from "./middlesbrough/navbar";
import Table from "./middlesbrough/table";
import { useNavigate } from 'react-router-dom';
import "./middlesbrough.scss";

function UserPageMiddlesbrough({ onLogout }) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('t');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
        onLogout(); 
      };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <br></br>
        <div className="banner">
        <div className="dummyBannerContent">
          Welcome Middlesbrough
        </div>
        <div className="BannerContent">
          Try your admin panel
        </div>
        <button className="bannerButton" onClick={() => navigate('/admin')}>
           Explore More
          </button>
      </div>
        <div className="listContainer">
          <h3>Please select from the below options</h3>
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'table' ? 'active' : ''}`}
              onClick={() => handleTabClick('table')}
            >
              Configuration file
              <br></br>
              <img src={`${new URL('../icons/1.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
            </div>
            

  <a
    className={`tab ${activeTab === 'Tab1' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/1fd810aa-2c5a-4ce4-a2ce-8c331c799b6f/page/zl8DD"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab1')}
  >
    Bus Live Data
    <br></br>
    <img src={`${new URL('../icons/2.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab2' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/57c9d5e9-ba2b-4898-8600-b31a8b3c95c5/page/M0ncC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab2')}
  >
    M02 visualisation
    <br></br>
    <img src={`${new URL('../icons/3.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab3' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/3d310411-c36e-407d-b9d7-4d9b194f758f/page/jHocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab3')}
  >
   
    M08 messages
    <br></br>
    <img src={`${new URL('../icons/4.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab4' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/e285818f-f1bc-4a53-b001-e0cd22855121/page/8cocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab4')}
  >
   
   M20 report
   <br></br>
   <img src={`${new URL('../icons/5.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab5' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/3556ea1d-702e-4b03-8bce-630ce3ad58a3/page/TlocC"
    target="_blank" 
    rel="noopener noreferrer"
    onClick={() => handleTabClick('Tab5')}
  >
   
   M37 report
   <br></br>
   <img src={`${new URL('../icons/6.png', import.meta.url).pathname}`} alt="Icon" className="tabIcon" />
  </a>

  <a
    className={`tab ${activeTab === 'Tab6' ? 'active' : ''}`}
    href="https://console.cloud.google.com/compute/instancesDetail/zones/europe-west2-c/instances/sipp-middlesbrough?project=middlesbrough-1fa1&pli=1"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab6')}
  >
   
   sipp Planner
   

  </a>
          </div>
          {activeTab === 'table' && (
            <React.Fragment>
              <div className="listTitle">Input and Configuration</div>
              <Table />
            </React.Fragment>
          )}
          {activeTab === 'Tab1' && (
            <React.Fragment>
              <div className="listTitle">Looker report</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab2' && (
            <React.Fragment>
              <div className="listTitle">Looker report 2</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab3' && (
            <React.Fragment>
              <div className="listTitle">Looker report 3</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab4' && (
            <React.Fragment>
              <div className="listTitle">Looker report 4</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab5' && (
              <React.Fragment>
                <div className="listTitle">Sipp AI Planner</div>
              </React.Fragment>
            )}

          <button className="logout" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default UserPageMiddlesbrough;