// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 300px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 6px;
  }
  
  input {
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .error {
    color: #ff0000;
    margin-top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/LoginForm.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,8BAA8B;IAC9B,SAAS;IACT,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,2CAA2C;IAC3C,YAAY;EACd;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;IACd,gBAAgB;EAClB","sourcesContent":["/* styles.css */\r\n\r\nbody {\r\n    font-family: Arial, sans-serif;\r\n    margin: 0;\r\n    padding: 0;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-color: #f4f4f4;\r\n  }\r\n  \r\n  .container {\r\n    background-color: #fff;\r\n    border-radius: 8px;\r\n    padding: 20px;\r\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);\r\n    width: 300px;\r\n  }\r\n  \r\n  h2 {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  form {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n  \r\n  label {\r\n    font-weight: bold;\r\n    margin-bottom: 6px;\r\n  }\r\n  \r\n  input {\r\n    padding: 10px;\r\n    margin-bottom: 12px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    outline: none;\r\n  }\r\n  \r\n  button {\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    border: none;\r\n    border-radius: 4px;\r\n    padding: 10px 15px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  \r\n  .error {\r\n    color: #ff0000;\r\n    margin-top: 10px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
