import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import RegistrationForm from './components/RegistrationForm';
import LoginForm from './components/LoginForm';
import AdminPage from './admin/AdminPage';
import UserPageMiddlesbrough from './pages/UserPageMiddlesbrough';
import UserPageHull from './pages/UserPageHull';
import UserPageKirklees from './pages/UserPageKirklees';
import PendingRequestPage from './pages/PendingRequestPage';
import './Navigation.css';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [location, setLocation] = useState(null);
  const [role, setRole] = useState(null);
  const [isApproved, setIsApproved] = useState(false);

  const handleLogin = (location, role, isApproved) => {
    setLoggedIn(true);
    setLocation(location);
    setRole(role);
    setIsApproved(isApproved); 
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setLocation(null);
    setRole(null);
    setIsApproved(false);
  };
  return (
    <Router>
      <Routes>
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
        {loggedIn && role === 'user' && (
          <>
            {location === 'Middlesbrough' && <Route path="/middlesbrough" element={<UserPageMiddlesbrough onLogout={handleLogout}/>} />}
            {location === 'Hull' && <Route path="/hull" element={<UserPageHull onLogout={handleLogout}/>} />}
            {location === 'Kirklees' && <Route path="/kirklees" element={<UserPageKirklees onLogout={handleLogout}/>} />}
          </>
        )}
        {loggedIn && role === 'admin' && <Route path="/admin" element={<AdminPage onLogout={handleLogout} />} />}
        {(isApproved === false) && <Route path="/pending-request" element={<PendingRequestPage />} />}
        <Route path="*" element={<Navigate to="/login" />} />
        
      </Routes>
    </Router>
  );
}

export default App;
