import React, { useState } from 'react';
import Sidebar from "./hull/sidebar";
import Navbar from "./hull/navbar";
import Table from "./hull/table";
import { useNavigate } from 'react-router-dom';
import "./middlesbrough.scss";

function UserPageHull({ onLogout }) {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('t'); 

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
        onLogout(); 
      };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <br></br>
        <div className="banner">
        <div className="dummyBannerContent">
          Welcome Hull
        </div>
        <div className="BannerContent">
          Try your admin panel
        </div>
        <button className="bannerButton" onClick={() => navigate('/admin')}>
           Explore More
          </button>
      </div>

        <div className="listContainer">
          <h3>Please select from the below options</h3>
          <div className="tabs">
            <div
              className={`tab ${activeTab === 'table' ? 'active' : ''}`}
              onClick={() => handleTabClick('table')}
            >
              Configuration file
            </div>
            

  <a
    className={`tab ${activeTab === 'Tab1' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/1e36d1c9-be9f-4a7d-991b-e11200b87373/page/p9nWD"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab1')}
  >
    SCOOT Traffic Data
  </a>

  <a
    className={`tab ${activeTab === 'Tab2' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/d398e012-0da8-49b9-8989-a87c12c15fa6/page/p_4rxnrhus6c"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab2')}
  >
    Bus Data

  </a>
{/* 
  <a
    className={`tab ${activeTab === 'Tab3' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/3d310411-c36e-407d-b9d7-4d9b194f758f/page/jHocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab3')}
  >
   
    M08 messages
  </a>

  <a
    className={`tab ${activeTab === 'Tab4' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/e285818f-f1bc-4a53-b001-e0cd22855121/page/8cocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab4')}
  >
   
   M20 report
  </a>

  <a
    className={`tab ${activeTab === 'Tab5' ? 'active' : ''}`}
    href="https://lookerstudio.google.com/reporting/3556ea1d-702e-4b03-8bce-630ce3ad58a3/page/TlocC"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab5')}
  >
   
   M37 report
  </a> */}

  {/* <a
    className={`tab ${activeTab === 'Tab6' ? 'active' : ''}`}
    href="https://console.cloud.google.com/compute/instancesDetail/zones/europe-west2-c/instances/sipp-middlesbrough?project=middlesbrough-1fa1&pli=1"
    target="_blank" 
    rel="noopener noreferrer" 
    onClick={() => handleTabClick('Tab6')}
  >
   
   sipp Planner
  </a> */}
          </div>
          {activeTab === 'table' && (
            <React.Fragment>
              <div className="listTitle">Input and Configuration</div>
              <Table />
            </React.Fragment>
          )}
          {activeTab === 'Tab1' && (
            <React.Fragment>
              <div className="listTitle">Looker report for SCOOT collections of traffic data including Average Speeds and Flow per location</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab2' && (
            <React.Fragment>
              <div className="listTitle">Looker report for Bus Data in Hull selectable by line</div>
            </React.Fragment>
          )}
          {/* {activeTab === 'Tab3' && (
            <React.Fragment>
              <div className="listTitle">Looker report 3</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab4' && (
            <React.Fragment>
              <div className="listTitle">Looker report 4</div>
            </React.Fragment>
          )}
          {activeTab === 'Tab5' && (
            <React.Fragment>
              <div className="listTitle">Sipp AI Planner</div>
            </React.Fragment>
          )} */}
          <button className="logout" onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default UserPageHull;