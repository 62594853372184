// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/RegistrationForm.css */

/* Overall form container */
.form-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  /* Form header */
  .form-header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Form fields */
  .form-field {
    margin-bottom: 15px;
  }
  
  .form-label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-input,
  .form-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Submit button */
  .form-submit {
    text-align: center;
    margin-top: 15px;
  }
  
  .form-submit button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-submit button:hover {
    background-color: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RegistrationForm.css"],"names":[],"mappings":"AAAA,wCAAwC;;AAExC,2BAA2B;AAC3B;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,sBAAsB;EACxB;;EAEA,gBAAgB;EAChB;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA,gBAAgB;EAChB;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;EACjB;;EAEA,kBAAkB;EAClB;IACE,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["/* src/components/RegistrationForm.css */\r\n\r\n/* Overall form container */\r\n.form-container {\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 8px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n    background-color: #fff;\r\n  }\r\n  \r\n  /* Form header */\r\n  .form-header {\r\n    font-size: 24px;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  /* Form fields */\r\n  .form-field {\r\n    margin-bottom: 15px;\r\n  }\r\n  \r\n  .form-label {\r\n    display: block;\r\n    font-weight: bold;\r\n    margin-bottom: 5px;\r\n  }\r\n  \r\n  .form-input,\r\n  .form-select {\r\n    width: 100%;\r\n    padding: 10px;\r\n    border: 1px solid #ccc;\r\n    border-radius: 4px;\r\n    font-size: 16px;\r\n  }\r\n  \r\n  /* Submit button */\r\n  .form-submit {\r\n    text-align: center;\r\n    margin-top: 15px;\r\n  }\r\n  \r\n  .form-submit button {\r\n    padding: 10px 20px;\r\n    background-color: #007bff;\r\n    color: #fff;\r\n    border: none;\r\n    border-radius: 4px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .form-submit button:hover {\r\n    background-color: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
