
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AdminPage({ onLogout }) {
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    axios.get('https://simplifai-backend.azurewebsites.net/getPendingRequests')
    // axios.get('http://localhost:3001/getPendingRequests')
      .then(response => {
        setPendingRequests(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const approveRequest = (userId) => {
    axios.post('https://simplifai-backend.azurewebsites.net/approveRequest', { userId })
    // axios.post('http://localhost:3001/approveRequest', { userId })
      .then(response => {
      })
      .catch(error => {
        console.error(error);
      });
  };

  const rejectRequest = (userId) => {
   
    axios.post('https://simplifai-backend.azurewebsites.net/rejectRequest', { userId })
    // axios.post('http://localhost:3001/rejectRequest', { userId })
      .then(response => {
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleLogout = () => {
    onLogout(); 
  };

  return (
    <div>
      <h1>Admin Page</h1>
      <h2>Pending Requests</h2>
      <ul>
        {pendingRequests.map(request => (
          <li key={request.userId}>
            {request.username} from {request.location}
            <button onClick={() => approveRequest(request.userId)}>Approve</button>
            <button onClick={() => rejectRequest(request.userId)}>Reject</button>
          </li>
        ))}
      </ul>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default AdminPage;
