// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Navigation.css */

nav {
    background-color: #333;
    color: white;
  }
  
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  nav ul li {
    margin-right: 20px;
  }
  
  nav ul li:last-child {
    margin-right: 0;
  }
  
  nav a {
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
  nav a:hover {
    color: #f2f2f2;
  }
  `, "",{"version":3,"sources":["webpack://./src/Navigation.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,qBAAqB;IACrB,SAAS;IACT,UAAU;IACV,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB","sourcesContent":["/* Navigation.css */\r\n\r\nnav {\r\n    background-color: #333;\r\n    color: white;\r\n  }\r\n  \r\n  nav ul {\r\n    list-style-type: none;\r\n    margin: 0;\r\n    padding: 0;\r\n    display: flex;\r\n  }\r\n  \r\n  nav ul li {\r\n    margin-right: 20px;\r\n  }\r\n  \r\n  nav ul li:last-child {\r\n    margin-right: 0;\r\n  }\r\n  \r\n  nav a {\r\n    text-decoration: none;\r\n    color: white;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  nav a:hover {\r\n    color: #f2f2f2;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
