import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; 
import './RegistrationForm.css';


function RegistrationForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [location, setLocation] = useState('');
  const [role, setRole] = useState('user');
  const navigate = useNavigate(); 

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://simplifai-backend.azurewebsites.net/register', {
        username,
        password,
        location,
        role: 'user',
      });

      // try {
      //   const response = await axios.post('http://localhost:3001/register', {
      //     username,
      //     password,
      //     location,
      //     role: 'user',
      //   });

      if (response.status === 201) {
        console.log('Registration successful');
        navigate('/login');
      }
    } catch (error) {
      console.error('Registration failed', error);
    }
  };

  return (
    <div className="container">
      <h2>Registration Form</h2>
      <form onSubmit={handleRegister}>
        <div className="form-field">
          <label>Username:</label>
          <input
            type="text"
            className="form-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-field">
          <label className="form-label">Password:</label>
          <input
            type="password"
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-field">
          <label className="form-label">Location:</label>
          <select
            className="form-select"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="">Select Location</option>
            <option value="Middlesbrough">Middlesbrough</option>
            <option value="Hull">Hull</option>
            <option value="Kirklees">Kirklees</option>
          </select>
        </div>
        <div className="form-submit">
          <button type="submit">Register</button>
        </div>
      </form>
    </div>
  );
  
}

export default RegistrationForm;
